export enum EErpTypes {
    NONE = 'NONE',
    LINX = 'LINX',
    SAAS = 'SAAS',
    SAVWIN = 'SAVWIN',
    DATAWEB = 'DATAWEB',
    INVISTTO = 'INVISTTO',
    SSOOTICA = 'SSOOTICA',
    CONTROL = 'CONTROL',
    VIXEN = 'VIXEN'
}

export const ALLOW_ERP_INTEGRATIONS: Array<EErpTypes> = [
    EErpTypes.LINX,
    EErpTypes.SAAS,
    EErpTypes.INVISTTO,
    EErpTypes.SAVWIN,
    EErpTypes.SSOOTICA,
    EErpTypes.DATAWEB,
    EErpTypes.VIXEN,
]

export const ALLOW_ERP_PRICE_TABLE: Array<EErpTypes> = [
    EErpTypes.LINX,
    EErpTypes.SAVWIN
]

export const ALLOW_ERP_PAYMENTS: Array<EErpTypes> = [
    EErpTypes.LINX,
    EErpTypes.SAAS,
    EErpTypes.CONTROL,
    EErpTypes.NONE,
    EErpTypes.VIXEN,
]

// Lista dos ERPs que devem consumir API GET /payment-plans/erp/{account}
export const ALLOW_LOAD_ERP_PAYMENTS: Array<EErpTypes> = [
    EErpTypes.LINX,
    EErpTypes.SAAS,
    EErpTypes.VIXEN,
]

// Lista dos ERPs que possui sync de produtos
export const ALLOW_ERP_SYNC_PROD: Array<EErpTypes> = [
    EErpTypes.LINX,
    EErpTypes.SAAS,
    EErpTypes.SAVWIN,
    EErpTypes.VIXEN,
]

// Dados utilizado para popular o select de ERP
export const erpTypes = [
    { key: EErpTypes.NONE, text: "Nenhum" },
    { key: EErpTypes.LINX, text: "ERP LINX" },
    { key: EErpTypes.SAAS, text: "SaaS" },
    { key: EErpTypes.CONTROL, text: "Control" },
    { key: EErpTypes.SAVWIN, text: "Savwin" },
    { key: EErpTypes.DATAWEB, text: "DataWeb" },
    { key: EErpTypes.INVISTTO, text: "Invistto" },
    { key: EErpTypes.SSOOTICA, text: "SSO Ótica" },
    { key: EErpTypes.VIXEN, text: "Vixen - WPI" },
].sort((a, b) => a.text.localeCompare(b.text))
