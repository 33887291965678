









































































































import {ALLOW_ERP_SYNC_PROD, EErpTypes} from '@/code/enums/EErpTypes';
import { ProductInfo } from '@/code/models/assistent/ProductInfo';
import { Account } from '@/code/models/User/Account';
import { AssistentService } from '@/code/services/assistent/assistentService';
import { AccountService } from '@/code/services/user/accountService';
import { EventBus } from '@/code/util/eventBus';
import { Loading, Toast } from '@/code/util/swalUtil';
import Swal from 'sweetalert2';
import Vue from 'vue';

export default Vue.extend({

  data() {
    return {
      productsInfo: [] as Array<ProductInfo>,
      idAccount: 0,
      account: {} as Account,
      syncError: ''
    }
  },

  mounted(): void {
    try {
      this.idAccount = parseInt(this.$route.params.id);
    } catch (ex: any) {
      this.idAccount = 0;
    }

    const that = this;
    if (this.idAccount && this.idAccount > 0) {
      this.loadAccount();
      this.getProductsInfo();
    } else {
      Swal.fire({
        title: 'Parâmetro inválido',
        text: 'A loja informada é invalida!',
        icon: 'error'
      })
      .then(
        (result) => {
          that.$router.replace({ name: 'assistentAction' });
        }
      )
    }
  },

  methods: {
    loadAccount: async function() {
      const accountService = new AccountService();
      this.account = await accountService.get(this.idAccount, true);
      this.syncError = this.account.sync_error;
    },
    getProductsInfo: async function() {
      if(this.isInfoVisible){
        return
      }

      Loading.fire("Carregando dados da sincronização!");
      const service = new AssistentService();
      this.productsInfo = await service.getProductInfo(this.idAccount);
      Swal.close();
    },
    previousTab: async function() {
      EventBus.$emit('previousAssistentRoute');
    },
    nextTab: async function() {
      EventBus.$emit('nextAssistentRoute');
    },
    startUpdate: async function() {
      Loading.fire("Iniciando a sincronização de produtos!");
      const service = new AssistentService();
      const result = await service.startUpdate(this.idAccount);
      if (result) {
        Swal.close();
        Toast.fire({
          text: 'Atualização iniciada com sucesso!',
          icon: 'success'
        });
      }
    },
    startRefresh: async function() {
      if(!confirm('Deseja apagar todos os produtos e sincronizar novamente?')) {
        return
      }

      Loading.fire("Apagando banco de dados e iniciando a sincronização!");
      const service = new AssistentService();
      const result = await service.startUpdate(this.idAccount, true);
      if (result) {
        Swal.close();
        Toast.fire({
          text: 'Limpeza + atualização iniciada com sucesso!',
          icon: 'success'
        });
        this.getProductsInfo()
      }
    },
    showErrorDetails: function() {
      Swal.fire({
        title: "Erro de sincronização",
        text: this.syncError,
        icon: 'error'
      });
    }
  },
  computed: {
    isInfoVisible() : Boolean {
      return ALLOW_ERP_SYNC_PROD.includes(EErpTypes[this.account.erp]);
    }
  }

})
